
  .d-flex.flex-column.h-100 {
    height: 100vh;
  }

  .custom-popover {
    --bs-popover-header-bg: var(--bs-gray-900);
    --bs-popover-header-color: var(--bs-light);
    --bs-popover-bg: var(--bs-gray-800);
    --bs-popover-body-color: var(--bs-light);
    --bs-popover-header-padding-x: 1rem;
    --bs-popover-header-padding-y: .5rem;
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: .5rem;
  }
  